/* Material UI Spinner */
.mui-spinner {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* For react router dom link */
.link {
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
/* For Image Tag */
.img {
  width: 100% !important;
  height: auto !important;
}
button.btnSimplified{
  border: 1px solid #5c26f8; 
}
/* button.btnSimplified:hover{
  background: #5c26f8; 
  color: white;
} */