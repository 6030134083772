.verify-otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.verify-otp-container input {
  margin: 8px;
  padding: 14px;
  width: 40px;
  height: 40px;
  color: #888097;
  border: 1.8px solid #888097;
  border-radius: 6px;
}
