#mobile-sidebar {
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 4px !important ;
  }

  ::-webkit-scrollbar-thumb {
    background: #5c26f8;
  }
}
.mobile-layout {
  /* flex-grow: 1; */
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .mobile-layout {
    padding: 8px;
  }
}
