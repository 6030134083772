@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  position: relative;
  overflow: hidden;
  border-radius: 0 18px 18px 0;
}
.link {
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
.logo {
  width: 150px;
  height: 40px;
}
/* chart */
.chart-container {
  padding: 4px 4px !important;
  padding-bottom: 30px !important;
  width: 100%;
  height: 260px;
  margin: 0 auto;
}
.chart-caption {
  margin: 5px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
}
/* .calculation-part {
  height: 40px;
} */
.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-size: 10px;
  color: #333;
  /* color: #5c26f8; */
  min-width: 80px !important;
}
.chart-tooltip-title {
  color: black;
  font-weight: 600;
}
/* header */
.header {
  border-radius: 10px 10px 0 0;
  height: 60px;
}
/* login card */
.login-card {
  height: 100%;
  border-radius: 18px 0 0 18px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
}
/*thrid-portion-box  */
.thrid-portion-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  margin: 0 0 7px 0;
  border-radius: 5px;
  background-color: #f6f8fe;
}
/* .TextInputFont .MuiInputBase-root {
  margin-top: 0px !important;
} */
.TextInput .MuiFormControl {
  margin-top: 0px !important;
}
.selectFormContorl .MuiInputBase-root {
  margin-top: 0px !important;
}

.landingCard {
  padding: 45px;
}
.profileForm {
  width: 86%;
}
 
@media only screen and (max-width: 375px) {
  /* textfield */
  .MuiInputBase-root,
  .MuiInput-root {
    margin-bottom: 1px !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-card {
    border-radius: 18px !important;
  }
  .logo {
    width: 25px !important;
    height: 25px !important;
  }
  .center-txt {
    font-size: small;
  }
  .gravity-logo-container {
    height: 26px;
  }
  .header {
    height: 52px !important;
  }
  .chart-container {
    padding-bottom: 60px !important;
    /* height: 275px; */
  }
  /* .calculation-part {
    height: 80px !important;
  } */
  /* textfield */
  .MuiInputBase-root,
  .MuiInput-root {
    margin-bottom: 4px !important;
  }
}
@media only screen and (max-width: 900px) {
  .header {
    border-radius: 10px 10px 0 0 !important;
  }
  /* .thrid-portion-box {
    margin: 55px 0 7px 0 !important;
  } */
}
.custom-scrollbar {
  /* Style the scrollbar for webkit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important ;
  }

  ::-webkit-scrollbar-thumb {
    background: #5c26f8;
  }
}
/* textfield */

.MuiInput-root {
  margin-bottom: 8px !important;
}

.scrollbarHide::-webkit-scrollbar {
  display: none !important;
}
.cell-fs {
  font-size: smaller !important;
}

.circle {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
}

.circle1 {
  border: 4px solid;
}

.circle2 {
  border: 4px solid green;
}

.circle3 {
  border: 4px solid blue;
}
